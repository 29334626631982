.gs-input-container-124 {
    width: auto;
    height: 45px;
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    // margin-bottom: 26px;
}

.gs-input-title-124 {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #444444;
    width: 150px;
    display: flex;
    justify-content: flex-start;
    height: 24px;
}

.gs-input-form-124 {
    margin-left: 40px;
    width: 439px;
    height: 38px;
    border-radius: 4px;
    border: solid 0.5px #757575;
    padding-left: 16px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999;
}

.gs-input-button-124 {
    width: 96px;
    height: 38px;
    border-radius: 4px;
    background-color: #0185ff;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 18px;
    cursor: pointer;
}