.gs-input-edit-form-126 {
    width: 439px;
    height: 38px;
    border-radius: 4px;
    border: solid 0.5px #757575;
    padding-left: 16px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999;
    margin-left: 35px;
}