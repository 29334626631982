.content-covid19{
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    width: 90%;
}

.text-contnt-98{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #333333;
}

.rght-cntnt{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.lft-cntnt{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.context-12-double{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.txt-blu-988{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #0185FF;
    margin-top: 18px;
}

.txt-blu-9880{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #0185FF;
    margin-top: 63px;
}

.txt-cntnt-gry{
    margin-top: 6px;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    text-align: left;
}

.imge-vck-1231{
    background-image: url('../../../images/landing-page-kabupaten/humanvck.svg');
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    background-position: center;
    margin-top: 54px;
}

.img-vck-dcktr{
    background-image: url('../../../images/landing-page-kabupaten/doctorvecktor.svg');
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    background-position: center;
}

.notes-ctnrt{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 25px;
    border-bottom: 0.5px solid #E8E8E8;
    margin-top: 60px;
}

.blue-cntn{
    width: 50%;
    background: #0B7BFF;
    box-shadow: 0px 4px 8px rgba(1, 133, 255, 0.45);
    border-radius: 15px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 37px;
    padding-bottom: 50px;
    margin-right: 1%;
}

.red-cntn{
    width: 50%;
    background: #FF6868;
    box-shadow: 0px 4px 8px rgba(1, 133, 255, 0.45);
    border-radius: 15px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 37px;
    padding-bottom: 40px;
}

.txt-blue-cntn{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    margin-bottom: 31px;
}

.txt-311-caa{
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #EAEAEA;
    margin-left: 25px;
}

.text-contrt-87613{
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.shield-x{
    background-image: url('../../../images/icons/xshield.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 25px;
    background-position: left;
    background-size: 1.3rem;
}

.shield-x2{
    background-image: url('../../../images/icons/xshield.svg');
    background-repeat: no-repeat;
    width: 37px;
    height: 25px;
    background-position: left;
    background-size: 1.3rem;
}

.shield-x3{
    background-image: url('../../../images/icons/xshield.svg');
    background-repeat: no-repeat;
    width: 26px;
    height: 25px;
    background-position: left;
    background-size: 1.3rem;
}

.shield-x4{
    background-image: url('../../../images/icons/xshield.svg');
    background-repeat: no-repeat;
    width: 31px;
    height: 25px;
    background-position: left;
    background-size: 1.3rem;
}

.box-whitr-1312{
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0px 5px 20px rgba(117, 117, 117, 0.1);
    border-radius: 15px;
    padding-top: 21px;
    // margin-top: 59px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
}