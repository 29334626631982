/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'ROGfonts';
    font-style: normal;
    font-weight: normal;
    src: local('ROGfonts'), url('AsusRog-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'SFProText';
    font-style: normal;
    font-weight: normal;
    src: local('SFProText'), url('SFProText-Regular.woff') format('woff');
}

@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: normal;
    src: local('OpenSans'), url('OpenSans-Regular.ttf') format('truetype');
}