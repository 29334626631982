@media only screen and (max-width: 784px) {

    .db-418-title-2 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        margin-top: 36px;
        width: 90%;
    }

    .db-418-title-3 {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
    }

}