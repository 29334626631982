@media only screen and (max-width: 700px) {
    .lpk-container-box-content-867 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0;
    }

    .lpk-content-867-rc {
        width: 100%;
        // background-color: pink;
    }

    .lpk-per-content-867 {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 0 !important;
    }
    

    .lpk-content-first-title1-867-majority {
        font-size: 9px;
    }

    .lpk-content-second-867 {
        font-size: 14px;
    }


    // BC
    // .bc-triple-per-content-597 {
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     margin-top: 0;
    // }

    .bc-triple-per-content-597 {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 0 !important;
    }
    
    .bc-majority-title1-597 {
        font-size: 9px;
    }

    .bct-sc-title2-597 {
        font-size: 14px;
    }

}

@media only screen and (max-width: 330px) {
    .lpk-content-second-867 {
        font-size: 13px;
        margin-right: 2%;
    }
    .bct-ncf-button-867 {
        width: 76px;
        height: 23px;
    }
}