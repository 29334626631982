.lpk-container-box-content-867 {
    // margin-top: 26px;
    width: 100%;
    // flex-direction: column;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    // background-color: pink;
}

.lpk-per-content-867 {
    width: 48%;
    height: 154px;
    // background-color: blueviolet;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
}

.lpk-content-first-867 {
    width: 100%;
    // height: 80%;
    height: 104px;
    display: flex;
    // background-color: black;
}

// ***** START KABUPATEN *****

.lpk-content-first-item-867-kabupaten {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lpk-content-first-hr-kabupaten-867 {
    height: 100%;
    width: 1px;
    background-color: #4C4EE4;
}

.lpk-content-first-title1-867-kabupaten {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 17px;
}

.lpk-content-first-title2-867-kabupaten {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 25px;
}

// ***** END KABUPATEN *****

// ***** START ALL *****

.lpk-content-first-item-867-all {
    width: 16.66%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lpk-content-first-title1-867-majority {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 17px;
    height: 50%;
    width: 90%;
}

.lpk-content-first-title2-867-majority {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    // margin-top: 11px;
    height: 50%;
}

.lpk-content-first-hr-all-867 {
    height: 100%;
    width: 1px;
    background-color: #4C4EE4;
}

// ***** END ALL *****

// ***** START ALL 2 *****

.lpk-content-first-item-867-all {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

// ***** END ALL 2 *****

.lpk-content-second-867 {
    width: 50%;
    margin-left: 24%;
    // height: 20%;
    height: 50px;
    // border-top: 2px solid #4C4EE4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.bct-ncf-button-867 {
    width: 84px;
    height: 28px;
    background: #0185FF;
    border-radius: 4px;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    text-align: center;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4%;
    margin-left: 1%;
    // margin-right: 20px;
}

.border-top-lpk-type-1-867 {
    border-top: 2px solid #CD4F4F;
}

.border-top-lpk-type-2-867 {
    border-top: 2px solid #EBB16D;
}

.border-top-lpk-type-3-867 {
    border-top: 2px solid #F1E398;
}

.border-top-lpk-type-4-867 {
    border-top: 2px solid #25A541;
}
.border-top-lpk-type-5-867 {
    border-top: 2px solid #71CE91;

}

@media only screen and (max-width: 1400px) {
    .bc-majority-title1-597 {
        font-size: 8.9px;
    }
}
@media only screen and (max-width: 1300px) {

    .lpk-content-second-867 {
        font-size: 15px;
    }

    .bct-ncf-button-867 {
        width: 80px;
        height: 25px;
        font-size: 13px;
    }

    .bc-majority-title1-597 {
        font-size: 8px;
        line-height: 13px;
    }

    .bct-sc-title2-597 {
        font-size: 15px;
    }

    .bc-majority-title2-597 {
        font-size: 17px;
    }

}

@media only screen and (max-width: 1160px) {

    .bc-triple-per-content-597 {
        height: 166px ;
        width: 48.8%!important;
    }

    .bct-first-content-597 {
        height: 100px;
    }

    .bct-second-content-597 {
        height: 58px;
    }

    .lpk-content-second-867 {
        font-size: 14px;
    }

    .bct-ncf-button-867 {
        width: 80px;
        height: 25px;
        font-size: 12px;
    }

    .bc-majority-title1-597 {
        font-size: 7px;
    }

    .bc-majority-title2-597 {
        font-size: 15px;
    }

    .bct-sc-title1-597 {
        font-size: 16px;
    }

    .bct-sc-title2-597 {
        font-size: 12px;
    }

    .bct-ncf-title1-597 {
        font-size: 34px;
        line-height: 20px;
    }
    
    .bct-ncf-title2-597 {
        font-size: 12px;
    }

}
