.ab-input-container-225 {
    width: auto;
    height: 45px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    // justify-content: flex-start;
    // margin-bottom: 26px;
}

.ab-input-title-225 {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #444444;
    width: 150px;
    display: flex;
    justify-content: flex-start;
    height: 24px;
}

.ab-input-form-225 {
    margin-left: 40px;
    width: 439px;
    height: 38px;
    border-radius: 4px;
    border: solid 0.5px #757575;
    padding-left: 16px;
    background-color: #F2F3F9;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999;
}

.ab-input-button-225 {
    width: 96px;
    height: 38px;
    border-radius: 4px;
    background-color: #0185ff;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    cursor: pointer;
}