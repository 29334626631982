.gs-edit-container-124 {
    width: auto;
    height: 45px;
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    margin-bottom: 24px;
    // border-bottom: 1px solid #8888
}

.gs-edit-title-124 {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #444444;
    width: 150px;
    display: flex;
    justify-content: flex-start;
    height: 24px;
}

.gs-edit-value-124 {
    margin-left: 40px;
    // width: 83px;
    width: 439px;
    height: 19px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #666666;
    display: flex;
    justify-content: flex-start;
}

.gs-edit-delete-container-124 {
    display: flex;
    margin-left: 12px;
}

.gs-circle-edit-content-124 {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.gs-circle-delete-content-124 {
    width: 32px;
    height: 32px;
    background-color: #ffffff;
    border-radius: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}

