@media only screen and (max-width: 784px) {

    .dpc-254-title-1-254 {
        font-size: 26px;
        width: 90%;
        line-height: 38px;
        margin-top: 48px;
    }

    .dpc-per-box-254 {
        width: 89%;
        height: auto;
        padding-top: 21.5px;
        padding-bottom: 22.5px;
    }

    .dpc-per-box-254-detail { 
        width: 89%;
        height: auto;
        padding-top: 21.5px;
        padding-bottom: 22.5px;
    }

    .dpc-per-box-item-254 {
        height: 100%;
        // justify-content: center;
        width: 92%;
        align-items: center;
    }

    .dpc-254-content-detail-254 {
        width: 89%;
    }

    .dpc-pbi-title-254 {
        font-size: 14px;
        width: 90%;
    }

    .dpc-word-content-254 {
        width: 100%;
        font-size: 12px;
    }
    .dpc-word-content-254 p {
        width: 100%;
        font-size: 12px;
    }
    .dpc-word-content-254 ul , li {
        width: 100%;
        font-size: 12px;
    }

}

@media only screen and (max-width: 375px) {
    .dpc-254-title-1-254 {
        font-size: 24px;
        margin-top: 42px;
    }
}

@media only screen and (max-width: 340px) {
    .dpc-254-title-1-254 {
        font-size: 21px;
        margin-top: 42px;
        margin-bottom: -35px;
    }
}