.daftar-pertanyaan-container-254 {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    height: auto;
}

html {
    scroll-behavior: auto;
}

.dpc-254-title-1-254 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    text-align: center;    
    color: #0185FF;
    width:  807px;
    height: 120px;
    margin-top: 59px;
}

.dpc-per-box-254 {
    // width: 1110px;
    width: 80%;
    height: 64px;
    background: #F4F6FC;
    // border: 0.3px solid #C4C4C4;
    border: 0.4px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
}

.dpc-per-box-254-detail {
    width: 80%;
    height: 64px;
    // height: 171px;
    background: #F4F6FC;
    // border: 0.4px solid #C4C4C4;
    box-sizing: border-box;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 0.4px solid #C4C4C4;
    border-left: 0.4px solid #C4C4C4;
    border-right: 0.4px solid #C4C4C4;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}


.dpc-254-content-detail-254 {
    width: 80%;
    // min-height: 107px;
    height: auto;
    background: #F4F6FC;
    box-sizing: border-box;
    border-bottom: 0.4px solid #C4C4C4;
    border-left: 0.4px solid #C4C4C4;
    border-right: 0.4px solid #C4C4C4;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    justify-content: center;
    // align-items: center;
}

.dpc-per-box-item-254 {
    display: flex;
    width: 95%;
    justify-content: space-between;
}

.dpc-pbi-title-254 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #333333;
}

.dpc-pbi-circle-254 {
    width: 22px;
    height: 22px;
    background-image: url('../../images/daftar-berita/circle.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    // border-radius: 100%;
    // background-size: contain;
    // background-color:#E1E1E1;
}

.dpc-word-content-254 {
    width: 80%;
    font-family: "OpenSans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    padding-bottom: 33px;
}

.dpc-word-content-254 p {
    width: 80%;
    font-family: "OpenSans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    // padding-bottom: 38px;
}


.dpc-word-content-254 ul , li {
    width: 80%;
    font-family: "OpenSans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    margin-left: 0;
    // color: pink;
}


@media only screen and (max-width: 784px) {

}