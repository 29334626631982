

.ab-edit-container-225 {
    width: auto;
    height: 45px;
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    margin-bottom: 24px;
    // border-bottom: 1px solid #8888
}

.ab-edit-title-225 {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #444444;
    width: 150px;
    display: flex;
    justify-content: flex-start;
    height: 24px;
}

.ab-edit-value-225 {
    margin-left: 40px;
    // width: 83px;
    width: 439px;
    height: 19px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #666666;
    display: flex;
    justify-content: flex-start;
}

.ab-edit-delete-container-225 {
    display: flex;
    margin-left: 12px;
}

.ab-circle-edit-content-225 {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ab-circle-delete-content-225 {
    width: 32px;
    height: 32px;
    background-color: #ffffff;
    border-radius: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}

.ab-input-edit-form-225 {
    margin-left: 40px;
    width: 439px;
    height: 38px;
    border-radius: 4px;
    border: solid 0.5px #757575;
    padding-left: 16px;
    background-color: #F2F3F9;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999; 
}

.ab-plus-edit-225 {
    width: 96px;
    height: 38px;
    border-radius: 4px;
    border: solid 1px #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0185ff;
    cursor: pointer;
    margin-left: 28px;
}

.ab-plus-input-edit-container-225 {
    display: flex;
    width: 860px;
    margin-left: 190px;
    margin-bottom: 24px;
    margin-top: 23px;
}

.ab-plus-input-edit-form-225 {
    width: 439px;
    height: 38px;
    border-radius: 4px;
    border: solid 0.5px #757575;
    padding-left: 16px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999;
}

.ab-plus-input-delete-form-225 {
    width: 96px;
    height: 38px;
    border-radius: 4px;
    border: solid 1px #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0185ff;
    cursor: pointer;
    // margin-left: 12px;
}

.ab-plus-input-save-form-225 {
    width: 96px;
    height: 38px;
    border-radius: 4px;
    background-color: #0185ff;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    cursor: pointer;
}

.ab-line-225 {
    width: 830px;
    height: 1px;
    background-color: #e8e8e8;
}

.ab-225-type-two-container {
    width: auto;
    height: auto;
    border-bottom: 0.5px solid #8888;
    margin-bottom: 24px;
}