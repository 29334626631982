.navbar-title-90 {
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f2f3f9;
    // padding-top: -10px;
    // padding-bottom: 20px;
}